<template>
    <div class="custom-card">
        <div class="custom-card-header">
            <h3 class="card-title">{{ title?.toUpperCase() }}</h3>
        </div>
        <div class="custom-card-body">
            <div class="chart-container">
                <apexchart v-if="data?.length" type="bar" :options="chartOptions" :series="series" height="300" />
            </div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    components: { apexchart: VueApexCharts },
    props: {
        data: { type: Array, required: true },
        title: { type: String, required: true },
        locale: { type: String, default: 'pt-br' },
    },
    computed: {
        preparedData() {
            return this.data
                .map(item => ({
                    name: this.abbreviateName(item.name),
                    fullName: item.name,
                    total: item.total,
                    read: item.read,
                    readPercent: item.readPercent,
                    unreadPercent: item.unreadPercent,
                }))
                .sort((a, b) => b.readPercent - a.readPercent)
        },
        chartOptions() {
            const totalString = this.$t('str.total')
            const ofString = this.$t('str.of')
            return {
                chart: {
                    type: 'bar',
                    stacked: true,
                    stackType: '100%',
                    height: '100%',
                    width: '100%',
                    zoom: { enabled: true },
                    toolbar: { show: true, tools: { download: true } },
                },
                plotOptions: {
                    bar: { horizontal: true, borderRadius: 4, barHeight: '70%' },
                },
                stroke: { width: 1, colors: ['#fff'] },
                yaxis: {
                    labels: {
                        style: { colors: '#fff', fontSize: '10px' },
                        minWidth: 0,
                        maxWidth: 100,
                    },
                },
                xaxis: {
                    categories: this.preparedData.map(item => item.name),
                    labels: {
                        trim: true,
                        hideOverlappingLabels: true,
                        style: { colors: '#fff', fontSize: 12 },
                        maxHeight: 100,
                    },
                    axisBorder: { show: true, color: '#fff' },
                    axisTicks: { show: true, color: '#fff' },
                    offsetX: 0,
                },
                tooltip: {
                    y: {
                        formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                            const data = w.config.series[seriesIndex].data[dataPointIndex]
                            const fullName = w.config.series[0].data[dataPointIndex].fullName
                            return `${fullName}: ${val}% (${totalString}: ${data.totalReadOrUnread} ${ofString} ${data.total})`
                        },
                    },
                },
                fill: {
                    opacity: 1,
                    colors: ['#117A65', '#B71C1C'],
                },
                legend: { show: false },
                responsive: [
                    {
                        breakpoint: 500,
                        options: {
                            plotOptions: { bar: { barHeight: '50%' } },
                            yaxis: { labels: { style: { fontSize: '10px' } } },
                            xaxis: { labels: { style: { fontSize: '10px' } } },
                        },
                    },
                ],
            }
        },
        series() {
            return [
                {
                    name: this.$t('str.chart.patrol.points.performance.read'),
                    data: this.preparedData.map(item => ({
                        x: item.name,
                        y: item.readPercent ? item.readPercent.toFixed(2) : 0,
                        total: item.total,
                        totalReadOrUnread: item.read,
                        fullName: item.fullName,
                    })),
                },
                {
                    name: this.$t('str.chart.patrol.points.performance.unread'),
                    data: this.preparedData.map(item => ({
                        x: item.name,
                        y: item.unreadPercent ? item.unreadPercent.toFixed(2) : 0,
                        total: item.total,
                        totalReadOrUnread: item.total - item.read,
                        fullName: item.fullName,
                    })),
                },
            ]
        },
    },
    methods: {
        abbreviateName(name) {
            if (!name || typeof name !== 'string') return 'N/A'
            const parts = name.trim().split(/\s+/)
            if (parts.length > 2) {
                return `${parts[0]} ${parts[1][0]}. ${parts[2]}`
            }
            return parts.join(' ')
        },
    },
}
</script>

<style scoped>
.chart-container {
    max-height: 700px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
}
.custom-card {
    background: linear-gradient(135deg, #161a1d, #20252a);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    color: #fff;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.custom-card-header {
    padding: 15px 20px;
    background: rgba(0, 0, 0, 0.3);
    text-align: center;
}
.card-title {
    margin: 0;
    color: #d6d6d6;
    font-size: 0.7rem;
    font-weight: 500;
}
.custom-card-body {
    padding: 20px;
    position: relative;
    min-height: 350px;
}
</style>
