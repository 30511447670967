<script>
import Common from '../../../common/Common.vue'
import Services from '../../../common/Services.vue'
import moment from 'moment'

export default {
    init: async payload => {
        await payload.initRangeDate()

        payload.filters.account = Common.getAccountId(payload)
        payload.isSuperAdminMaster = await Common.isSuperAdminMaster(payload)

        const role = await Common.getSubtype(payload)
        payload.role = role

        if (role === 'SUPER_ADMIN_MASTER') {
            payload.accounts = await Services.getAccounts(payload)
        } else if (role === 'ADMIN' || role === 'MANAGER') {
            payload.clients = await Services.getClients(payload)
        } else if (role === 'MANAGER' || role === 'OPERATOR' || role === 'AUDITOR') {
            const client = await Common.getClientId(payload)
            payload.filters.client = client
            payload.sites = await Services.getSites(payload)
        }
    },
    methods: {
        async filter() {
            if (this.isLoading) return

            if (this.role === 'SUPER_ADMIN_MASTER' && !this.filters.account) {
                Common.show(this, 'top-right', 'warn', this.$t('str.charts.select.account.required'))
                return
            }

            this.clearCharts()
            this.isLoading = true

            try {
                const [localsPerformance, guardsPerformance] = await Promise.all([Services.localsPerformance(this, this.filters), Services.guardsPerformance(this, this.filters)])

                this.localsPerformance = localsPerformance
                this.guardsPerformance = guardsPerformance
            } catch (error) {
                console.error('Error loading charts data:', error)
            } finally {
                this.isLoading = false
            }
        },
        clearCharts() {
            this.localsPerformance = []
            this.guardsPerformance = []
        },
        initRangeDate: async function () {
            const startDate = moment().utc(true).subtract(0, 'days')
            const endDate = moment().utc(true)

            this.dateRange = {
                opens: 'right',
                singleDatePicker: false,
                timePicker: false,
                timePicker24Hour: false,
                showWeekNumbers: false,
                showDropdowns: true,
                autoApply: false,
                linkedCalendars: true,
                closeOnEsc: true,
                range: {
                    startDate: startDate,
                    endDate: endDate,
                },
                maxDate: moment().utc(true).format(),
                sampleLocaleData: {
                    direction: 'ltr',
                    format: this?.userLocale === 'pt-br' ? 'dd/mm/yyyy' : 'mm/dd/yyyy',
                    separator: ' - ',
                    applyLabel: this.$t('str.apply'),
                    cancelLabel: this.$t('str.cancel'),
                    weekLabel: 'W',
                    ranges: this.rangeDates,
                    daysOfWeek: [
                        this.$t('str.abbreviation.sunday'),
                        this.$t('str.abbreviation.monday'),
                        this.$t('str.abbreviation.tuesday'),
                        this.$t('str.abbreviation.wednesday'),
                        this.$t('str.abbreviation.thursday'),
                        this.$t('str.abbreviation.friday'),
                        this.$t('str.abbreviation.saturday'),
                    ],
                    monthNames: [
                        this.$t('str.abbreviation.january'),
                        this.$t('str.abbreviation.february'),
                        this.$t('str.abbreviation.march'),
                        this.$t('str.abbreviation.april'),
                        this.$t('str.abbreviation.may'),
                        this.$t('str.abbreviation.june'),
                        this.$t('str.abbreviation.july'),
                        this.$t('str.abbreviation.august'),
                        this.$t('str.abbreviation.september'),
                        this.$t('str.abbreviation.october'),
                        this.$t('str.abbreviation.november'),
                        this.$t('str.abbreviation.december'),
                    ],
                    firstDay: 0,
                },
            }
        },
        onStartSelection: async function (d) {
            console.log('onStartSelection', d)
        },
        onFinishSelection: async function (d) {
            console.log('onFinishSelection', d)
        },
        async updateValues(d) {
            const startDate = moment(d.startDate).utc(true)
            const endDate = moment(d.endDate).utc(true)
            // Verificação para garantir que o intervalo não seja maior que 30 dias
            if (endDate.diff(startDate, 'days') > 30) {
                Common.show(this, 'top-right', 'warn', this.$t('str.charts.date.range.max'))

                this.dateRange.range.startDate = moment().utc(true).subtract(30, 'days')
                this.dateRange.range.endDate = moment().utc(true)
                this.filters.startDate = startDate
                this.filters.endDate = endDate
            } else {
                this.dateRange.range.startDate = startDate
                this.dateRange.range.endDate = endDate
                this.filters.startDate = startDate
                this.filters.endDate = endDate

                await this.filter()
            }
        },
        changeAccount: async function () {
            const account = this.filters.account

            this.filters.client = ''
            this.filters.site = ''

            this.filter()
            this.clients = await Services.getClientsByAccount(this, account)
        },
        changeClient: async function () {
            const client = this.filters.client

            this.filters.site = ''

            this.filter()
            this.sites = await Services.getSitesByClient(this, client)
        },
        changeSite: async function () {
            this.filter()
        },
        getStatusName: Common.getEventStatusName,
        formatDate: Common.formatDate,
    },
}
</script>
