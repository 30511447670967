<template>
    <div>
        <button type="submit" class="btn btn-primary" @click="viewPDF">
            <i v-if="isLoading" class="fas fa-spinner fa-pulse" />
            PDF
        </button>
    </div>
</template>

<script>
import jsPDF from 'jspdf'
import moment from 'moment'
import QRCode from 'qrcode'

export default {
    name: 'PdfDownload',
    props: {
        jsonData: {
            type: [Array, Object],
            default: () => [],
        },
        pdfHeader: {
            type: Array,
            default: () => [],
        },
        jsonTitle: {
            type: String,
            default: '',
        },
        filename: {
            type: String,
            default: '',
        },
        reportName: {
            type: String,
            default: '',
        },
        logoURL: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isLoading: false,
            data: [],
            title: '',
            headers: [],
            name: '',
            report: '',
        }
    },
    watch: {
        jsonData: {
            immediate: true,
            handler(newVal) {
                // Converte em array achatado se vier como objeto
                if (Array.isArray(newVal)) {
                    this.data = newVal
                } else {
                    this.data = Object.values(newVal).flat()
                }
                this.title = this.jsonTitle
                this.headers = this.pdfHeader
                this.name = this.filename + '.pdf'
                this.report = this.reportName
            },
        },
        jsonTitle(newVal) {
            this.title = newVal
        },
        pdfHeader(newVal) {
            this.headers = newVal
        },
        filename(newVal) {
            this.name = newVal + '.pdf'
        },
        reportName(newVal) {
            this.report = newVal
        },
    },
    methods: {
        async downloadPDF() {
            this.isLoading = true

            // Passo 1: Prepara array de dados
            let items = this.data
            if (!Array.isArray(items)) {
                items = Object.values(items).flat()
            }

            // Passo 2: Ajusta cada item ao formato desejado
            const allData = items.map(item => ({
                _id: item._id || '',
                patrolPointCode: item.patrolPointCode || '',
                siteName: item.site?.name || '',
            }))

            // Passo 3: Agrupa por siteName
            const groupedBySite = allData.reduce((acc, current) => {
                const site = current.siteName || 'SEM SITE'
                if (!acc[site]) acc[site] = []
                acc[site].push(current)
                return acc
            }, {})

            // Instancia o jsPDF
            const doc = new jsPDF({ orientation: 'p', unit: 'mm', format: 'a4' })

            // Função para adicionar o cabeçalho (logo, título, data)
            const addHeader = async (doc, y) => {
                if (this.logoURL) {
                    try {
                        const imgData = await this.loadImageWithWhiteBackground(this.logoURL)
                        const imgProps = doc.getImageProperties(imgData)
                        const imgWidth = 25
                        const imgHeight = (imgProps.height * imgWidth) / imgProps.width
                        const centerX = (doc.internal.pageSize.getWidth() - imgWidth) / 2
                        doc.addImage(imgData, 'PNG', centerX, y, imgWidth, imgHeight)
                        y += imgHeight + 10
                    } catch (error) {
                        console.warn('Erro ao carregar o logo:', error)
                    }
                }

                doc.setFont('helvetica', 'bold')
                doc.setFontSize(13)
                const pageWidth = doc.internal.pageSize.getWidth()
                doc.text(this.title.toUpperCase(), pageWidth / 2, y, { align: 'center' })
                y += 5

                doc.setFontSize(10)
                doc.setFont('helvetica', 'normal')
                doc.text('Gerado em: ' + moment().format('DD/MM/YYYY HH:mm:ss'), pageWidth / 2, y, { align: 'center' })
                y += 10
                return y
            }

            // Helper para verificar se ainda há espaço na página
            const checkPageSpaceAndMaybeAdd = (doc, y, neededSpace) => {
                const pageHeight = doc.internal.pageSize.getHeight()
                // margem de 10mm do rodapé, por exemplo
                if (y + neededSpace > pageHeight - 10) {
                    doc.addPage()
                    return 10
                }
                return y
            }

            let x = 10
            let y = 10
            y = await addHeader(doc, y)

            // Tamanho e espaçamento dos QR Codes
            const qrSize = 36
            const colSpacing = 37
            const blockHeight = 42 // altura de cada "bloco" (texto + QR)

            // Itera sobre cada site em ordem alfabética ou de chaves
            const siteNames = Object.keys(groupedBySite)
            // siteNames.sort() // caso queira em ordem alfabética

            try {
                for (let s = 0; s < siteNames.length; s++) {
                    const siteName = siteNames[s]
                    const siteItems = groupedBySite[siteName]

                    // AQUI é onde definimos o espaço maior entre sites
                    // Se não for o primeiro site, adiciona um espaço maior
                    if (s !== 0) {
                        // Verifica se cabe esse espaço
                        y = checkPageSpaceAndMaybeAdd(doc, y, 20)
                        y += 5 // Ajuste o valor conforme queira
                        x = 10
                    }

                    // Nome do site
                    doc.setFontSize(10)
                    doc.setFont('helvetica', 'bold')
                    doc.text(siteName, x, y)
                    y += 2

                    x = 10

                    // Renderiza cada QR Code desse site
                    for (let i = 0; i < siteItems.length; i++) {
                        const { _id, patrolPointCode } = siteItems[i]

                        // Se já houver 5 QR Codes na linha, pula para a próxima
                        if (i !== 0 && i % 5 === 0) {
                            // Antes de mudar de linha, checa se cabe o blocoHeight
                            y = checkPageSpaceAndMaybeAdd(doc, y, blockHeight)
                            y += blockHeight
                            x = 10
                        }

                        // Se estiver prestes a desenhar o QR Code, checa se cabe no resto da página
                        y = checkPageSpaceAndMaybeAdd(doc, y, blockHeight)

                        // Texto acima do QR, centralizado
                        doc.setFontSize(10)
                        doc.setFont('helvetica', 'normal')
                        doc.text(patrolPointCode, x + qrSize / 2, y + 5, { align: 'center' })

                        // Gera o QR Code
                        const qrImageData = await QRCode.toDataURL(_id, { errorCorrectionLevel: 'H' })
                        // Desenha o QR Code logo abaixo do texto
                        doc.addImage(qrImageData, 'PNG', x, y + 5, qrSize, qrSize)

                        x += colSpacing
                    }

                    // Após concluir todos os QR Codes desse site,
                    // avança para baixo se ainda houver outro site
                    // (aqui, 5 mm de espaço ou blockHeight, depende do layout desejado)
                    y = checkPageSpaceAndMaybeAdd(doc, y, blockHeight)
                    y += blockHeight
                    x = 10
                }

                this.isLoading = false

                // Retorna o PDF como Blob
                return new Promise(resolve => {
                    const pdfArrayBuffer = doc.output('arraybuffer')
                    const pdfBlob = new Blob([pdfArrayBuffer], { type: 'application/pdf' })
                    resolve(pdfBlob)
                })
            } catch (error) {
                this.isLoading = false
                console.error('Erro ao gerar o PDF:', error)
                throw error
            }
        },

        // Abre o PDF gerado em uma nova janela
        async viewPDF() {
            try {
                this.isLoading = true
                const pdfData = await this.downloadPDF()
                this.isLoading = false

                const pdfURL = URL.createObjectURL(pdfData)
                const width = window.innerWidth * 0.8
                const height = window.innerHeight * 0.8
                const left = (window.innerWidth - width) / 2
                const top = (window.innerHeight - height) / 2
                const windowFeatures = `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,toolbar=yes,menubar=no,location=no,status=yes`

                const previewWindow = window.open(pdfURL, '_blank', windowFeatures)
                if (previewWindow) {
                    previewWindow.print = function () {
                        previewWindow.close()
                    }
                }
            } catch (error) {
                this.isLoading = false
                console.error('Erro ao visualizar o PDF:', error)
            }
        },

        async loadImageWithWhiteBackground(url) {
            return new Promise((resolve, reject) => {
                const img = new Image()
                img.crossOrigin = 'Anonymous'
                img.onload = () => {
                    const canvas = document.createElement('canvas')
                    const ctx = canvas.getContext('2d')
                    canvas.width = img.width
                    canvas.height = img.height

                    ctx.fillStyle = '#FFFFFF'
                    ctx.fillRect(0, 0, canvas.width, canvas.height)
                    ctx.drawImage(img, 0, 0)
                    resolve(canvas.toDataURL('image/png'))
                }
                img.onerror = () => reject(new Error('Falha ao carregar a imagem'))
                img.src = url
            })
        },
    },
}
</script>
