<template>
    <div class="custom-card">
      <div class="custom-card-header">
        <h3 class="card-title">
          {{ $t('str.msg.analysis.patrols.read.vs.unread').toUpperCase() }}
        </h3>
      </div>
      <div class="custom-card-body">
        <!-- Gráfico de pizza -->
        <apexchart
          :options="chartOptions"
          :series="chartSeries"
          type="pie"
          height="240"
          ref="statusPieChart"
        ></apexchart>
        <!-- Legenda customizada fixa a 15px do fundo do card -->
        <div class="custom-legend">
          <div
            class="legend-item"
            v-for="(label, index) in chartOptions.labels"
            :key="index"
          >
            <span
              class="legend-color"
              :style="{ background: chartOptions.colors[index] }"
            ></span>
            <span class="legend-text">
              {{ label }}: {{ chartSeries[index] }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import pt from 'apexcharts/dist/locales/pt-br.json'
  import en from 'apexcharts/dist/locales/en.json'
  
  export default {
    name: 'PatrolStatusPieChart',
    props: {
      data: {
        type: Object,
        default: () => ({ read: 0, unread: 0 }),
      },
      locale: {
        type: String,
        default: 'pt-br',
      },
    },
    data() {
      return {
        chartSeries: [],
        chartOptions: {
          colors: ['#117A65', '#B71C1C'],
          chart: {
            type: 'pie',
            locales: [pt, en],
            defaultLocale: this.locale,
            animations: {
              enabled: true,
              easing: 'easeinout',
              speed: 800,
            },
          },
          // Ocultamos a legenda padrão para usar a customizada
          legend: {
            show: false,
          },
          labels: [this.$t('str.read'), this.$t('str.unread')],
          tooltip: {
            y: {
              formatter: function (val) {
                return `${val}`
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: (val, opts) => {
              return `${val.toFixed(2)}% (${opts.w.globals.series[opts.seriesIndex]})`
            },
          },
          stroke: {
            width: 1,
          },
        },
      }
    },
    watch: {
      data: {
        deep: true,
        immediate: true,
        handler(newData) {
          if (newData) {
            this.processData(newData)
          }
        },
      },
      locale: {
        immediate: true,
        handler(newLocale) {
          this.chartOptions.chart.defaultLocale = newLocale
          this.chartOptions.labels = [this.$t('str.read'), this.$t('str.unread')]
          this.updateChart()
        },
      },
    },
    mounted() {
      this.processData(this.data)
    },
    methods: {
      processData(data) {
        const read = data?.read || 0
        const unread = data?.unread || 0
        this.chartSeries = [read, unread]
        this.updateChart()
      },
      updateChart() {
        if (this.$refs.statusPieChart) {
          this.$refs.statusPieChart.updateOptions(this.chartOptions, true, false)
          this.$refs.statusPieChart.updateSeries(this.chartSeries)
        }
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .custom-card {
    background: linear-gradient(135deg, #161a1d, #20252a);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    color: #fff;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  .custom-card-header {
    padding: 15px 20px;
    background: rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  
  .card-title {
    margin: 0;
    color: #d6d6d6;
    font-size: 0.7rem; /* fonte reduzida */
    font-weight: 500;
  }
  
  .custom-card-body {
    padding: 20px;
    position: relative;
    min-height: 350px;
  }
  
  /* Legenda customizada fixa a 15px do fundo do card */
  .custom-legend {
    position: absolute;
    bottom: 15px;
    left: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    padding: 6px 10px;
    border-radius: 4px;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin: 0 7px;
    font-size: 0.7rem; /* fonte reduzida */
  }
  
  .legend-color {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
    display: inline-block;
  }
  
  .legend-text {
    line-height: 1;
  }
  </style>
  