<template>
    <div class="custom-card">
        <div class="custom-card-header">
            <h3 class="card-title">
                {{ $t('str.msg.analysis.checkpoints.perday').toUpperCase() }}
            </h3>
        </div>
        <div class="custom-card-body">
            <apexchart v-if="chart?.series && chart?.series.length" :key="componentKey" id="chart" ref="chart" type="bar" width="100%" height="300" :options="chart.options" :series="chart.series"></apexchart>
        </div>
    </div>
</template>

<script>
import ApexCharts from 'apexcharts'
import pt from 'apexcharts/dist/locales/pt-br.json'
import en from 'apexcharts/dist/locales/en.json'
import moment from 'moment'

export default {
    name: 'CheckpointsPerDay',
    props: ['data', 'redirect', 'locale'],
    data() {
        return {
            componentKey: 0,
            chart: {
                series: [],
                options: {
                    // Para bar charts com 3 séries, mantenho a paleta original (se desejar ajustar, basta trocar os valores)
                    colors: ['#70BBFF', '#117A65', '#B71C1C'],
                    chart: {
                        type: 'bar',
                        height: 350,
                        toolbar: { show: false },
                        locales: [pt, en],
                        defaultLocale: this.locale || 'pt-br',
                        animations: {
                            enabled: true,
                            easing: 'easeinout',
                            speed: 800,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded',
                        },
                    },
                    dataLabels: { enabled: false },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent'],
                    },
                    xaxis: {
                        type: 'category',
                        categories: [],
                        labels: { style: { colors: '#fff' } },
                    },
                    yaxis: {
                        title: { text: this.$t('str.checkpoints') },
                        labels: { style: { colors: '#fff' } },
                    },
                    fill: { opacity: 1 },
                    tooltip: {
                        y: {
                            formatter: val => `${val} ${this.$t('str.checkpoints')}`,
                        },
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        labels: { colors: '#fff' },
                    },
                },
            },
        }
    },
    watch: {
        data: {
            deep: true,
            handler(newData) {
                if (newData) this.processData(newData)
            },
        },
        locale: {
            immediate: true,
            handler(newLocale) {
                this.chart.options.chart.defaultLocale = newLocale || 'pt-br'
                this.processData(this.data)
                ApexCharts.exec('chart', 'updateLocale', newLocale)
            },
        },
    },
    methods: {
        processData(data) {
            if (!data || Object.keys(data).length === 0) {
                // this.chart.series = [{ name: '', data: [] }]
                return
            }

            const categories = []
            const readSeries = []
            const unreadSeries = []
            const totalSeries = []
            const readData = {},
                unreadData = {},
                totalData = {}

            data.read.forEach(item => {
                const date = item[0]
                readData[date] = item[1]
            })
            data.unread.forEach(item => {
                const date = item[0]
                unreadData[date] = item[1]
            })
            data.totalPerDay.forEach(item => {
                const date = item[0]
                totalData[date] = item[1]
            })

            const allDates = Array.from(new Set([...Object.keys(totalData), ...Object.keys(readData), ...Object.keys(unreadData)]))
            allDates.forEach(date => {
                categories.push(
                    moment(date)
                        .utc(false)
                        .format(this.locale === 'pt-br' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'),
                )
                readSeries.push(readData[date] || 0)
                unreadSeries.push(unreadData[date] || 0)
                totalSeries.push(totalData[date] || 0)
            })

            this.chart.options.xaxis.categories = categories
            this.chart.series = [
                { name: this.$t('str.predicted'), data: totalSeries },
                { name: this.$t('str.read'), data: readSeries },
                { name: this.$t('str.unread'), data: unreadSeries },
            ]

            if (this.$refs.chart) {
                this.$refs.chart.updateOptions({
                    xaxis: { categories: this.chart.options.xaxis.categories },
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.custom-card {
    background: linear-gradient(135deg, #161a1d, #20252a);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    color: #fff;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.custom-card-header {
    padding: 15px 20px;
    background: rgba(0, 0, 0, 0.3);
    text-align: center;
}
.card-title {
    margin: 0;
    color: #d6d6d6;
    font-size: 0.7rem;
    font-weight: 500;
}
.custom-card-body {
    padding: 20px;
    position: relative;
    min-height: 350px;
}
</style>
