<template>
    <div class="col-md-2">
        <div 
            class="widget widget-stats"
            :style="{ backgroundColor: background }"
            @click="navigate"
        >
            <div class="stats-icon-bg">
                <i :class="icon"></i>
            </div>
            <div class="stats-content">
                <h4 class="stats-title">{{ title }}</h4>
                <p class="stats-value">{{ value }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card',
    props: {
        title: {
            type: String,
            required: true,
        },
        value: {
            type: [String, Number],
            required: true,
        },
        background: {
            type: String,
            default: 'rgba(17, 122, 101, 0.8)', // Verde sofisticado
        },
        icon: {
            type: String,
            default: 'fa fa-desktop',
        },
        redirect: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {
        navigate() {
            if (this.redirect.name) {
                const params = this.redirect.params
                if (!params?.account) delete params.account
                if (!params?.client) delete params.client
                if (!params?.site) delete params.site

                this.$router.push({ path: this.redirect.name + JSON.stringify(params) })
            }
        },
    },
}
</script>

<style scoped>
.widget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120px;
    border-radius: 12px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.widget:hover {
    transform: scale(1.05);
}

/* Ícone como background com melhor visibilidade */
.stats-icon-bg {
    position: absolute;
    bottom: -15px;
    right: -15px;
    font-size: 110px; /* Aumentei para maior impacto visual */
    color: rgba(255, 255, 255, 0.06); /* Opacidade reduzida para mais integração */
}

/* Conteúdo do card */
.stats-content {
    text-align: center;
    z-index: 2;
}

.stats-title {
    font-size: 14px;
    color: #ffffff;
    opacity: 0.8;
    margin-bottom: 5px;
}

.stats-value {
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
}
</style>
