<template>
    <div class="custom-card">
        <div class="custom-card-header">
            <h3 class="card-title">
                {{ $t('str.msg.analysis.events.perday').toUpperCase() }}
            </h3>
        </div>
        <div class="custom-card-body">
            <apexchart v-if="chart?.series && chart?.series?.length" :key="componentKey" id="chart" ref="chart" type="bar" width="100%" height="300" :options="chart.options" :series="chart.series"></apexchart>
        </div>
    </div>
</template>

<script>
import ApexCharts from 'apexcharts'
import pt from 'apexcharts/dist/locales/pt-br.json'
import en from 'apexcharts/dist/locales/en.json'
import moment from 'moment'

export default {
    name: 'EventsPerDay',
    props: ['data', 'redirect', 'locale'],
    data() {
        return {
            componentKey: 0,
            chart: {
                series: [],
                options: {
                    colors: ['#117A65', '#B71C1C'],
                    chart: {
                        height: '100%',
                        type: 'bar',
                        toolbar: { show: false },
                        locales: [pt, en],
                        defaultLocale: this.locale || 'pt-br',
                        animations: {
                            enabled: true,
                            easing: 'easeinout',
                            speed: 800,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded',
                        },
                    },
                    xaxis: {
                        type: 'category',
                        categories: [],
                        labels: { style: { colors: '#fff' } },
                    },
                    yaxis: {
                        labels: { style: { colors: '#fff' } },
                    },
                    tooltip: {
                        y: {
                            formatter: val => `${val} ${this.$t('str.patrols')}`,
                        },
                    },
                    fill: { opacity: 1 },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        labels: { colors: '#fff' },
                    },
                },
            },
        }
    },
    watch: {
        data: {
            deep: true,
            handler(newData) {
                if (newData) this.processData(newData)
            },
        },
        locale: {
            immediate: true,
            handler(newLocale) {
                this.chart.options.chart.defaultLocale = newLocale || 'pt-br'
                this.processData(this.data)
                ApexCharts.exec('chart', 'updateLocale', newLocale)
            },
        },
    },
    methods: {
        getUniqueDates(statusArray) {
            const dateSet = new Set()
            statusArray.forEach(item => {
                item.data.forEach(([, date]) => dateSet.add(date))
            })
            return Array.from(dateSet).sort((a, b) => new Date(a) - new Date(b))
        },
        processData(newData) {
            if (newData && newData.status) {
                const categories = this.getUniqueDates(newData.status)
                this.chart.series = newData.status.map(item => ({
                    name: this.$t(item.name),
                    data: categories.map(date => {
                        const match = item.data.find(([, d]) => d === date)
                        return match ? match[0] : 0
                    }),
                }))
                this.chart.options.xaxis.categories = categories.map(date =>
                    moment(date)
                        .utc(false)
                        .format(this.locale === 'pt-br' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'),
                )
                if (this.$refs.chart) {
                    this.$refs.chart.updateOptions({
                        xaxis: { categories: this.chart.options.xaxis.categories },
                    })
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.custom-card {
    background: linear-gradient(135deg, #161a1d, #20252a);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    color: #fff;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.custom-card-header {
    padding: 15px 20px;
    background: rgba(0, 0, 0, 0.3);
    text-align: center;
}
.card-title {
    margin: 0;
    color: #d6d6d6;
    font-size: 0.7rem;
    font-weight: 500;
}
.custom-card-body {
    padding: 20px;
    position: relative;
    min-height: 350px;
}
</style>
