<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card stats-bar">
                <div class="stats-container">
                    <!-- Total de Eventos -->
                    <div class="stat-item stat-total">
                        <h4 class="stat-label">{{ $t('str.events.total') }}:</h4>
                        <label class="stat-value">{{ totalEvents }}</label>
                    </div>

                    <!-- Eventos Atendidos -->
                    <div class="stat-item stat-attended">
                        <span class="stat-label">{{ $t('str.events.attended') }}:</span>
                        <span class="stat-value">{{ attendedEvents }}</span>
                    </div>

                    <!-- Eventos SOS -->
                    <div class="stat-item stat-sos">
                        <span class="stat-label">{{ $t('str.events.sos') }}:</span>
                        <span class="stat-value">{{ sosEvents }}</span>
                    </div>

                    <!-- Eventos Incidentes -->
                    <div class="stat-item stat-incident">
                        <span class="stat-label">{{ $t('str.events.incidents') }}:</span>
                        <span class="stat-value">{{ incidentEvents }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StatsBar',
    props: {
        totalEvents: {
            type: Number,
            default: 0,
        },
        attendedEvents: {
            type: Number,
            default: 0,
        },
        sosEvents: {
            type: Number,
            default: 0,
        },
        incidentEvents: {
            type: Number,
            default: 0,
        },
    },
}
</script>

<style lang="scss" scoped>
.stats-bar {
    background: #2f3336;
    border: 1px solid #444;
    border-radius: 4px;
    padding: 0.125rem 0.125rem;
}

.stats-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.stat-item {
    display: flex;
    align-items: baseline;
    padding: 0 1rem;
    border-right: 1px solid #555;
    color: #fff;
}

.stat-item:last-child {
    border-right: none;
}

.stat-label {
    font-size: 0.85rem;
    font-weight: 600;
    color: #9fa6b2;
    margin-right: 0.25rem;
}

.stat-value {
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
}

.stat-total .stat-value {
    color: #ffffff;
}

.stat-attended .stat-value {
    color: #4caf50; /* verde */
}

.stat-sos .stat-value {
    color: #ff4444; /* vermelho */
}

.stat-incident .stat-value {
    color: #ffbb33; /* laranja */
}
</style>
