<template>
    <div :key="valuekey">
        <h1 class="page-header">{{ $t('str.msg.dashboard.general.summary.title') }}</h1>
        <hr />

        <div class="row">
            <div class="col-md-3">
                <label class="form-label" for="accountField">{{ $t('str.register.period') }}</label>
                <date-range-picker
                    ref="picker"
                    class="form-select btn btn-dark me-2"
                    :opens="dateRange.opens"
                    :locale-data="dateRange.sampleLocaleData"
                    :locale="dateRange.locale"
                    :singleDatePicker="dateRange.singleDatePicker"
                    :timePicker="dateRange.timePicker"
                    :timePicker24Hour="dateRange.timePicker24Hour"
                    :showWeekNumbers="dateRange.showWeekNumbers"
                    :showDropdowns="dateRange.showDropdowns"
                    v-model="dateRange.range"
                    :max-date="dateRange.maxDate"
                    :ranges="dateRanges"
                    :linkedCalendars="dateRange.linkedCalendars"
                    @update="updateValues"
                    @start-selection="onStartSelection"
                    @finish-selection="onFinishSelection"
                >
                    <template v-slot:input="filters">
                        <i class="fa fa-calendar fa-fw text-white text-opacity-50 ms-n1"></i>
                        {{ formatDate(filters.startDate) }} - {{ formatDate(filters.endDate) }}
                        <b class="caret ms-1 opacity-5"></b>
                    </template>
                </date-range-picker>
            </div>
            <div v-if="isSuperAdminMaster" class="col-md-3">
                <label class="form-label" for="accountField">{{ $t('str.register.incident.account.field') }}</label>
                <select v-model="filters.account" @change="changeAccount" class="form-select" id="accountField">
                    <option value="">{{ $t('str.register.select.placeholder') }}</option>
                    <option v-for="account in accounts" :value="account._id" :key="account._id">
                        {{ account.name }}
                    </option>
                </select>
            </div>
            <div v-if="role === 'SUPER_ADMIN_MASTER' || role === 'ADMIN' || role === 'MANAGER'" class="col-md-3">
                <label class="form-label" for="clientField">{{ $t('str.register.incident.client.field') }}</label>
                <select v-model="filters.client" @change="changeClient" class="form-select" id="clientField">
                    <option value="">{{ $t('str.register.select.placeholder') }}</option>
                    <option v-for="client in clients" :value="client._id" :key="client._id">
                        {{ client.name }}
                    </option>
                </select>
            </div>
            <div class="col-md-3">
                <label class="form-label" for="siteField">{{ $t('str.register.incident.site.field') }}</label>
                <select v-model="filters.site" @change="changeSite" class="form-select" id="siteField">
                    <option value="">{{ $t('str.register.select.placeholder') }}</option>
                    <option v-for="site in sites" :value="site._id" :key="site._id">
                        {{ site.name }}
                    </option>
                </select>
            </div>
        </div>

        <div class="row mt-20px">
            <Card
                :title="$t('str.dashboard.analisys.scheduled.events.total').toUpperCase()"
                :value="eventsSummary?.total || 0"
                :subtitle="$t('str.dashboard.view.details')"
                background="rgba(0, 0, 0, 0.3)"
                :redirect="{
                    name: '/timeline/schedule-patrols/',
                    params: {
                        startDate: filters.startDate,
                        endDate: filters.endDate,
                        account: filters.account,
                        client: filters.client,
                        site: filters.site,
                        status: '',
                    },
                }"
                icon="fa fa-calendar-check"
            />
            <Card
                :title="$t('str.dashboard.checkpoints.schedule').toUpperCase()"
                :value="checkpointsSummary?.total || 0"
                :subtitle="$t('str.dashboard.view.details')"
                background="rgba(0, 0, 0, 0.3)"
                :redirect="{
                    name: '/timeline/schedule-patrols/',
                    params: {
                        startDate: filters.startDate,
                        endDate: filters.endDate,
                        account: filters.account,
                        client: filters.client,
                        site: filters.site,
                        status: 'FINISHED',
                    },
                }"
                icon="fa fa-qrcode"
            />
            <Card :title="$t('str.dashboard.average.patrol.time').toUpperCase()" :value="patrolsChart?.averagePatrol || '0h 0m 0s'" subtitle="" background="rgba(0, 0, 0, 0.3)" icon="fa fa-stopwatch" />
            <Card :title="$t('str.dashboard.total.patrol.time').toUpperCase()" :value="patrolsChart?.totalPatrolTime || '0h 0m 0s'" subtitle="" background="rgba(0, 0, 0, 0.3)" icon="fa fa-stopwatch" />
            <Card :title="$t('str.dashboard.sos.total.alerts').toUpperCase()" :value="patrolsChart?.sosAlertTotal || 0" subtitle="" background="rgba(0, 0, 0, 0.3)" icon="fa fa-bell" />
            <Card :title="$t('str.dashboard.attendances').toUpperCase()" :value="patrolsChart?.attendences || 0" subtitle="" background="rgba(0, 0, 0, 0.3)" icon="fa fa-headset" />
        </div>

        <div class="row">
            <div class="col-xl-4">
                <PatrolCompletionPieChart :data="eventsSummary" :locale="userLocale" />
            </div>
            <div class="col-md-4">
                <PatrolStatusPieChart :data="checkpointsSummary" :locale="userLocale" />
            </div>
            <div class="col-xl-4">
                <PatrolCompletionStatusPieChart :data="eventsSummary" :locale="userLocale" />
            </div>
        </div>

        <div v-if="isLoading" class="spinner-overlay">
            <b-spinner label="Carregando..." style="width: 2rem; height: 2rem" type="border" variant="primary" />
        </div>

        <notifications group="top-right" position="top right" :speed="1000" />
    </div>
</template>

<script>
import Vue from 'vue'

import Card from './Components/Card.vue'
import PatrolCompletionPieChart from './Components/PatrolCompletionPieChart.vue'
import PatrolCompletionStatusPieChart from './Components/PatrolCompletionStatusPieChart.vue'
import PatrolStatusPieChart from './Components/PatrolStatusPieChart.vue'
import Controller from './CrtGeneralSummary.vue'
import moment from 'moment'

Vue.prototype.$registerEvent = new Vue()

export default {
    components: {
        Card,
        PatrolCompletionPieChart,
        PatrolCompletionStatusPieChart,
        PatrolStatusPieChart,
    },
    data() {
        return {
            isLoading: false,
            valuekey: 1,
            dateRange: {},

            filters: {
                account: '',
                client: '',
                site: '',
                category: 'SECURITY_PATROL',
                startDate: moment().utc(true),
                endDate: moment().utc(true),
            },
            userLocale: 'pt-br',
            maxDate: moment().utc(true).format(),
            accounts: [],
            clients: [],
            sites: [],
            role: '',
            patrolsChart: {},
            eventsSummary: {},
            checkpointsSummary: {},
            isSuperAdminMaster: false,
            dateRanges: {
                [this.$t('str.date.today')]: [moment().utc(true).toDate(), moment().utc(true).toDate()],
                [this.$t('str.date.yesterday')]: [moment().utc(true).subtract(1, 'days').toDate(), moment().utc(true).subtract(1, 'days').toDate()],
                [this.$t('str.date.this.month')]: [moment().utc(true).startOf('month').toDate(), moment().utc(true).toDate()],
                [this.$t('str.date.last.month')]: [moment().utc(true).subtract(1, 'month').startOf('month').toDate(), moment().utc(true).subtract(1, 'month').endOf('month').toDate()],
            },
        }
    },
    methods: Controller.methods,
    created() {
        Controller.init(this)
        console.log('dateRanges', this.dateRanges)

        const state = this
        state.$registerEvent.$on('changeLanguage', function () {
            state.userLocale = state.$i18n.locale === 'pt' ? 'pt-br' : 'en'
            state.valuekey += 1
            console.log('dateRanges', state.dateRanges)
            state.initRangeDate()
            state.$refs.picker.$data.ranges = state.dateRanges
        })
    },
    computed: {
        isDateRangeValid() {
            const { startDate, endDate } = this.dateRange.range
            return moment(endDate).diff(moment(startDate), 'days') <= 30
        },
    },
}
</script>
<style lang="scss" scoped>
.vue-daterange-picker.btn {
    padding: 2px !important;
    display: flex !important;
    background-color: var(--app-component-bg) !important;
    text-align-last: start !important;
}
.caret {
    display: none !important;
}

.row {
    display: flex;
    flex-wrap: wrap;
}
.col-md-8,
.col-md-4 {
    display: flex;
    flex-direction: column;
}
.spinner-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
